import React from "react"
import PageReqStatus from "../../templates/pageStatus"
import { SuccessContent } from "../../components/email/SuccessContent"

const seoData = {
  title: "Подарок за адрес электронной почты, карта Халва Совкомбанк",
}

const pageContext = {
  seoData,
  pageStatus: {
    success: true,
    content: <SuccessContent />,
  },
}

export default function SuccessVerifyPage() {
  return <PageReqStatus pageContext={pageContext} />
}
