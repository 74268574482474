import React, { useEffect } from "react"

import * as styles from "./successContent.module.scss"

const href = "https://share.flocktory.com/exchange/login?ssid=2996&bid=3435"

export function SuccessContent() {
  useEffect(() => {
    const timerId: NodeJS.Timeout = setTimeout(() => {
      window.location.href = href
    }, 3000)
    return () => {
      clearTimeout(timerId)
    }
  }, [])

  return (
    <>
      <div className={styles.title}>Ваш адрес подтвержден</div>
      <div className={styles.description}>
        Сейчас Вы будете направлены на страницу с Вашим подарком
      </div>
      <div className={styles.hint}>Если Вас не переадресовало, пройдите по ссылке:</div>
      <a className={styles.link} href={href}>
        {href}
      </a>
    </>
  )
}
